class helperFunctions {
  // This executes when the function is instantiated.
  constructor() {
    this.checkboxes_click();
  }

  checkboxes_click() {
    // Use event delegation to ensure any fields added after the page loads are captured.
    document.addEventListener("click", (e) => {
      if (e.target && e.target.className == "checkboxes_click") {
        $('.nested-fields .checkboxes_click').not(e.target).each(function(i, obj) {
          obj.checked = false;
        });
      }
    });
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener("turbolinks:load", () => new helperFunctions());
